export const directorConstants = {
    GET_SCHEDULE: "GET_SCHEDULE",
    APPROVAL_STATUS_CHANGE: "APPROVAL_STATUS_CHANGE",
    GET_DOCTOR: "GET_DOCTOR",
    GET_CUSTOMER: "GET_CUSTOMER",
    GET_ASSIGNED_TO: "GET_ASSIGNED_TO",
    GET_UID_APPROVAL: "GET_UID_APPROVAL",
    GET_PRODUCTS_ALL: "GET_PRODUCTS_ALL",
    GET_PRODUCTS_NEW: "GET_PRODUCTS_NEW",
    GET_DISTRIBUTION_CENTER: "GET_DISTRIBUTION_CENTER",
    GET_DEPARTMENT_HEAD: "GET_DEPARTMENT_HEAD",
    GET_ASSIGNED_TO_RSM: "GET_ASSIGNED_TO_RSM",
    GET_ASSIGNED_TO_AM: "GET_ASSIGNED_TO_AM",
    GET_ASSIGNED_TO_MPO: "GET_ASSIGNED_TO_MPO",
    GET_SCHEDULE_SINGLE_UID: "GET_SCHEDULE_SINGLE_UID",
    GET_SINGLE_PRODUCT_OBJ: "GET_SINGLE_PRODUCT_OBJ",
    GET_SINGLE_DISTRIBUTIONCENTER_OBJ: "GET_SINGLE_DISTRIBUTIONCENTER_OBJ",
    GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_ALL: "GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_ALL",
    GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_MED: "GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_MED",
    GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_GIFT: "GET_SINGLE_DISTRIBUTIONCENTER_OBJ_STOCK_GIFT",
  };
  
  export const LOADER_CONSTANT = {
    SM_LOADING: 'SM_LOADING',
    RSM_LOADING: 'RSM_LOADING',
    AM_LOADING: 'AM_LOADING',
    MPO_LOADING: 'MPO_LOADING',
  };
  
export const VIEW_USER_CONSTANT = {
  GET_SM: 'GET_SM',
  GET_RSM: 'GET_RSM',
  GET_AM: 'GET_AM',
  GET_MPO: 'GET_MPO',
};
export const VIEW_SM_CONSTANT = {
  VIEW_SM_LOADING: 'VIEW_SM_LOADING',
  VIEW_SM_SUCCESS: 'VIEW_SM_SUCCESS',
  VIEW_SM_ERROR: 'VIEW_SM_ERROR',
};
export const VIEW_RSM_CONSTANT = {
  VIEW_RSM_LOADING: 'VIEW_RSM_LOADING',
  VIEW_RSM_SUCCESS: 'VIEW_RSM_SUCCESS',
  VIEW_RSM_ERROR: 'VIEW_RSM_ERROR',
};

// Concord Doctors
export const DOCTOR_CONSTANT = {
  DOCTOR_LOADING: 'DOCTOR_LOADING',
  DOCTOR_SUCESS: 'DOCTOR_SUCESS',
  DOCTOR_ERROR: 'DOCTOR_ERROR'
}

export const STOCKS_CONSTANT = {
  STOCKS_LOADING: 'STOCKS_LOADING',
  STOCKS_SUCESS: 'STOCKS_SUCESS',
  STOCKS_ERROR: 'STOCKS_ERROR',
}