export const deportConstants = {
  GET_OLD_ORDER: "GET_OLD_ORDER",
  GET_ORDER: "GET_ORDER",
  GET_NEW_ORDER: "GET_NEW_ORDER",
  GET_STOCKS_PRODUCT: "GET_STOCKS_PRODUCT",
  GET_STOCKS_MEDICINE_PRODUCT: "GET_STOCKS_MEDICINE_PRODUCT",
  GET_STOCKS_GIFT_PRODUCT: "GET_STOCKS_GIFT_PRODUCT",
  STATUS_CHANGE: "STATUS_CHANGE",
  GET_PRODUCT_STATE: "GET_PRODUCT_STATE",
  GET_UID: "GET_UID",
  FIELD_DATA: "FIELD_DATA",
};
